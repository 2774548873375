.ProgressBar {


    width: 100%;
    color: "red";
    border-radius: 40;
    margin: 50;

    .ProgressBarFill {
        height: 100%;
        border-radius: 40;
        text-align: 'right';
    }

    .ProgressText {
        padding: 10;
        color: "black";
        font-weight: 900;
    }


}



.UserLimits {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.UserLimit {
    text-align: center;
    width: 45vw;
}

.UserLimitModalContentForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}