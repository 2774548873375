.EntryFormBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .EntryFormBoxProperty {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}