.Modal {
    display: flex;
    /* Hidden by default */
    position: fixed;
    /* Sit on top */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    opacity: 0;

    .ModalContent {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 40%;

        .ModalContentClose {
            display: flex;
            align-items: flex-start;
        }

    }
}

.Modal.Show {
    opacity: 1;
    pointer-events: visible;
}