.Login {

    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;

    .LoginBox {
        background-color: #fefefe;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 40vh;
        width: 40vw;
        align-items: center;
        min-height: 200px;
        min-width: 300px;
        filter: drop-shadow(0 0 5px white);

        .LoginBoxIconDiv {
            height: 20vh;

            .LoginBoxIconDivImg {

                max-width: 100%;
                max-height: 100%;
            }


        }

        .LoginBoxButton {
            width: fit-content;
            height: fit-content;
        }
    }
}