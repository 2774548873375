.DeleteEntryIconImg {
    max-height: 100%;
    max-width: 100%;
    height: 10vh;
    cursor: pointer;
}

.DeleteEntryIconImg:hover {
    filter: invert(.7)
}

.EntriesSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

    .EntriesHeader {
        width: 80vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .EntriesHeaderIcon {
            align-items: center;
            display: flex;
            justify-content: center;

            .EntriesHeaderIconImg {
                width: 5vmin;
                height: 5vmin;
                max-width: 100%;
                max-height: 100%;
                cursor: pointer;

            }

            .EntriesHeaderIconImg:hover {
                filter: invert(.7);
            }

        }

    }

    .EntriesList {
        width: 84vw;
        display: flex;
        max-height: 60vh;
        flex-direction: column;
        overflow-y: auto;
        border: solid;
        border-color: orange;

        .Entry {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .EntryProperty {
                width: 12vw;
                text-align: center;
            }

            .EntryIcon {
                width: 12vw;
                align-items: center;
                display: flex;
                justify-content: center;

                .EntryIconImg {
                    width: 3vmin;
                    height: 3vmin;
                    max-width: 100%;
                    max-height: 100%;
                    cursor: pointer;
                }

                .EntryIconImg:hover {
                    filter: invert(.5)
                }

            }
        }
    }


}