.UserPage {
    text-align: center;

    .UserHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .UserHeaderLogo {
            height: 20vh;
            max-height: 100%;
            max-width: 100%;
        }

        .UserHeaderLogout {
            display: flex;
            flex-direction: column;
            justify-content: center
        }

        .UserHeaderName {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center
        }

        .UserHeaderButtons {
            display: flex;
            flex-direction: column;
            height: 20vh;
            justify-content: space-evenly;

            .UserHeaderButton {
                height: 7vh;
                max-height: 100%;
                max-width: 100%;
            }

            .UserHeaderButton:hover {
                cursor: pointer;
                filter: invert(.5)
            }

        }
    }

    .UserContent {
        height: 90vh;

    }
}