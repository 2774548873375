@use "./SignUp.scss";
@use "./App.scss";
@use "./User.scss";
@use "./Limits.scss";
@use "./Entries.scss";
@use "./Modal.scss";
@use "./EntryForm.scss";
@use "./Admin.scss";
@use "./Login.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}