.AdminHeader {
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .AdminHeaderLogo {
        height: 20vh;
        max-height: 100%;
        max-width: 100%;
    }

    .AdminLogout {
        height: 10vh;
        max-height: 100%;
        max-width: 100%;
    }

    .AdminLogout:hover {
        filter: inver(.5);
        cursor: pointer;
    }
}

.UserReports {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .UserReportUser {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        .UserReportUserColumns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .UserReportUserTitlesTitle {
                width: 25vw;
            }
        }
    }

    .UserReportsDeleteUser {
        background-color: #f44336;
        border-radius: 8px;
        transition-duration: 0.4s;
        padding: .5vmin 1vmin;

        border: none;
    }

    .UserReportsDeleteUser:hover {
        background-color: orange;
    }
}

.TotalStatsDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .TotalStatsDivStats {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

    }
}

.UnauthorizedUser {
    display: flex;
    flex-direction: column;
    align-items: center;

    .UnauthorizedImage {
        max-width: 100%;
        max-height: 100%;
        height: 40vh;
    }

}